/* -------------------------------------------------------------------------- */
/*                             Adds product to cart                           */
/* -------------------------------------------------------------------------- */

document.addEventListener("DOMContentLoaded", function () {
  // imageHover();
  (function ($) {
    $(document).on("click", ".single_add_to_cart_button, .add_to_cart_button", function (e) {
      e.preventDefault();

      console.log("add to cart button has been clicked");
      var $thisbutton = $(this),
        $form = $thisbutton.closest("form.cart"),
        id = $thisbutton.val(),
        product_qty = $form.find("input[name=quantity]").val() || 1,
        product_id = $form.find("input[name=product_id]").val() || id,
        variation_id = $form.find("input[name=variation_id]").val() || 0;

      console.log(variation_id);

      var data = {
        action: "woocommerce_ajax_add_to_cart",
        product_id: product_id,
        product_sku: "",
        quantity: product_qty,
        variation_id: variation_id,
      };

      $(document.body).trigger("adding_to_cart", [$thisbutton, data]);

      $.ajax({
        type: "post",
        url: wc_add_to_cart_params.ajax_url,
        data: data,
        beforeSend: function (response) {
          $thisbutton.removeClass("added").addClass("loading");
          console.log("loading...");
        },
        complete: function (response) {
          $thisbutton.addClass("added").removeClass("loading");
        },
        success: function (response) {
          if (!response.error) {
            console.log("added class to button");
            console.log("product added to cart and bag has been updated");
          }
          $(".woo-modal").addClass("woo-modal__active");

          if (response.error && response.product_url) {
            // Create a new div element
            var newDiv = document.createElement("ul");
            newDiv.classList.add("woocommerce-error");

            // Add any content or attributes you want to the new div
            newDiv.innerHTML =
              "Je kunt dit aantal niet aan je winkelwagen toevoegen — we hebben er 1 op voorraad en je hebt er al 1 in je winkelwagen.";

            // Find the woocommerce-notices-wrapper container
            var noticesWrapper = document.querySelector(
              ".woocommerce-notices-wrapper"
            );

            // Insert the new div element inside the noticesWrapper
            if (noticesWrapper) {
              noticesWrapper.appendChild(newDiv);
            }
            console.log("Error occured, please see message.");
            return;
          } else {
            $(document.body).trigger("added_to_cart", [
              response.fragments,
              response.cart_hash,
              $thisbutton,
            ]);
          }
        },
      });

      return false;
    });
  })(jQuery);

  if (document.getElementsByClassName("continue-shop").length >= 1) {
    var shopBtn = document.getElementsByClassName("continue-shop");
    var modal = document.querySelector(".woo-modal");
    for (var i = 0; i < shopBtn.length; i++) {
      shopBtn[i].addEventListener("click", function (e) {
        e.preventDefault();
        // console.log("clickie");
        modal.classList.remove("woo-modal__active");
      });
    }
  }

  (function ($) {
    $(".highlighted-block--title-wrap__filter--link__btn").on(
      "click",
      function (e) {
        e.preventDefault();

        console.log("Filter has been selected");

        // Get all the elements with the class 'highlighted-block--title-wrap__filter--link'
        var linkElements = document.querySelectorAll(
          ".highlighted-block--title-wrap__filter--link"
        );
        var gridWrap = document.querySelector(".highlighted-block__products");

        // Add a click event listener to each element
        linkElements.forEach(function (linkElement) {
          linkElement.addEventListener("click", function () {
            // Remove the 'highlighted-block--title-wrap__filter--link--active' class from all elements
            linkElements.forEach(function (link) {
              link.classList.remove(
                "highlighted-block--title-wrap__filter--link--active"
              );
              link.classList.add(
                "highlighted-block--title-wrap__filter--link--inactive"
              );
            });

            // Remove the 'highlighted-block--title-wrap__filter--link--inactive' class from the clicked element
            linkElement.classList.remove(
              "highlighted-block--title-wrap__filter--link--inactive"
            );

            // Add the 'highlighted-block--title-wrap__filter--link--active' class to the clicked element
            linkElement.classList.add(
              "highlighted-block--title-wrap__filter--link--active"
            );
          });
        });

        $(".highlighted-block--title-wrap__filter--link__btn").removeClass(
          "highlighted-block--title-wrap__filter--link--active"
        );
        $(this).addClass("highlighted-block--title-wrap__filter--link--active");

        $.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          dataType: "html",
          data: {
            action: "behandelstoelen_featured",
            category: $(this).data("category"),
          },
          beforeSend: function (res) {
            console.log("Loading & Processing...");
            // gridWrap.classList.add('loading');
            // Create loader div
            $(
              "<div class=lds-ring><div></div><div></div><div></div><div></div></div>"
            ).insertAfter(gridWrap);
          },

          success: function (res) {
            $(".highlighted-block__products").html(res);
            $(".lds-ring").remove();
            imageHover();
            console.log("Products loaded!");
          },
        });
      }
    );
    return false;
  })(jQuery);

  jQuery(function ($) {
    /* -------------------------------------------------------------------------- */
    /*                                  Minicart                                  */
    /* -------------------------------------------------------------------------- */

    $(".mini-cart-btn , .mini-cart-btn-mobile").on("click", function (e) {
      e.preventDefault();
      console.log('clicked');
      $.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        dataType: "html",
        data: {
          action: "behandelstoelen_mini_cart",
          // category: $(this).data("category"),
        },
        beforeSend: function (res) {
          $(".site-nav__cart").addClass("site-nav__cart--loading");
          $(".site-nav__cart-mobile--wrap").addClass(
            "site-nav__cart-mobile--wrap__loading"
          );
          $(document.body).trigger("wc_fragment_refresh");
        },
        success: function (res) {
          console.log(res);
          $(".site-nav__cart").removeClass("site-nav__cart--loading");
          $(".site-nav__cart-mobile--wrap").removeClass(
            "site-nav__cart-mobile--wrap__loading"
          );

          $("#behandelstoelenCart").empty();
          $("#mobiCart").empty();
          $('.site-nav__cart').addClass('site-nav__cart__active');
          $("#behandelstoelenCart").html(res);
          $("#mobiCart").html(res);
        },
      });
    });

    $.ajax({
      type: "POST",
      url: "/wp-admin/admin-ajax.php",
      dataType: "html",
      data: {
        action: "behandelstoelen_mini_cart_count",
      },
      beforeSend: function (res) { },
      success: function (res) {
        $(document.body).trigger("wc_fragment_refresh");
        if (res >= 1) {
          $(".cart-counter").html(res);
          if (!$(".cart-counter").hasClass("mini-cart-btn__counter--counted")) {
            $(".cart-counter").addClass("mini-cart-btn__counter--counted");
          }
        } else {
          if ($(".cart-counter").hasClass("mini-cart-btn__counter--counted")) {
            $(".cart-counter").removeClass("mini-cart-btn__counter--counted");
          }
        }
      },
    });
  });
});

function imageHover() {
  var highlightedItems = document.querySelectorAll(
    ".highlighted-block__row--list"
  );
  var shop = document.querySelectorAll(".shop-layout__content");

  if (highlightedItems.length >= 1 || shop.length >= 1) {
    highlightedItems.forEach(function (item) {
      var frontImage = item.querySelector(
        ".highlighted-block__row--list--item-wrap__link--image__front"
      );
      var backImage = item.querySelector(
        ".highlighted-block__row--list--item-wrap__link--image__back"
      );

      var originalSrc = backImage.getAttribute("src");
      var dataSrc = backImage.getAttribute("data-src");

      var mouseEnterHandler = function () {
        backImage.setAttribute("src", dataSrc);
        backImage.setAttribute("data-src", originalSrc);
      };

      var touchStartHandler = function () {
        backImage.setAttribute("src", dataSrc);
        backImage.setAttribute("data-src", originalSrc);
      };

      item.addEventListener("mouseover", mouseEnterHandler);
      item.addEventListener("touchstart", touchStartHandler);

      item.addEventListener("mouseleave", function () {
        if (dataSrc !== null) {
          backImage.setAttribute("src", dataSrc);
          backImage.setAttribute("data-src", originalSrc);
        }
      });
    });
  }
}

jQuery(function ($) {
  $(document).ajaxStop(function () {
    imageHover();
  });
});
