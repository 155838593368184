/* -------------------------------------------------------------------------- */
/*          Enables user to open and close the categories or filters          */
/* -------------------------------------------------------------------------- */

document.addEventListener("DOMContentLoaded", function () {
  var acc = document.getElementsByClassName("behandelstoelen-cats__title");
  var i;
  var mediaQuery = "(max-width: 992px)"; // Changed media query to 992px

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function (e) {
      e.preventDefault();
      this.classList.toggle("behandelstoelen-cats__title--active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight !== "0px") {
        panel.style.maxHeight = "0px";
      } else {
        panel.style.maxHeight = 5000 + "px";
      }
    });

    if (window.matchMedia(mediaQuery).matches === true) {
      if (acc[i].classList.contains("behandelstoelen-cats__title--active")) {
        acc[i].nextElementSibling.style.maxHeight = "0px";
        acc[i].classList.remove("behandelstoelen-cats__title--active");
      }
      var shopLayoutSide = document.querySelector(".shop-layout__side");
      shopLayoutSide.style.maxHeight = "0px";
    }
  }
});
/* -------------------------------------------------------------------------- */
/*                             Update ajax counter                            */
/* -------------------------------------------------------------------------- */

function updateQty() {
  var update = document.getElementsByName("update_cart")[0];
  if (update) {
    update.click();
  }
}

function qtyPlusMin() {
  if (document.querySelectorAll(".qty").length >= 1) {
    var plus = document.getElementsByClassName("plus");
    var minus = document.getElementsByClassName("minus");
    var errorShown = false; // Flag to track if the error div has been shown
    var currentMaxValue = 0; // Variable to store the current max value

    const qtyTimeout = setTimeout(updateQty, 1000);

    // Helper function to check if the value exceeds the max attribute
    function checkMaxValue(inputElement) {
      const value = parseInt(inputElement.value);
      const maxValue = parseInt(inputElement.getAttribute("max"));

      if (!isNaN(value) && !isNaN(maxValue) && value >= maxValue) {
        if (!errorShown || currentMaxValue !== maxValue) {
          // Check if error has not been shown already or the max value has changed
          errorShown = true; // Set the flag to true to indicate the error has been shown
          currentMaxValue = maxValue; // Update the current max value

          const errorDiv = document.createElement("ul");
          errorDiv.classList.add("woocommerce-error");
          errorDiv.setAttribute("role", "alert");

          const productDivs = document.querySelectorAll('.product-name');
          productDivs.forEach(productDiv => {
            const aTag = productDiv.querySelector('a');
            if (aTag) {
              const productName = aTag.innerText;
              // console.log(productName);
            }
          });

          const errorMessage = document.createElement("li");
          errorMessage.textContent = "Maximaal " + maxValue + " stuk(s) beschikbaar voor dit artikel";

          errorDiv.appendChild(errorMessage);

          // Wrapping the errorDiv with additional divs
          const containerDiv = document.createElement("div");
          containerDiv.classList.add("container");

          const rowDiv = document.createElement("div");
          rowDiv.classList.add("row");

          // Append the errorDiv to the rowDiv, and the rowDiv to the containerDiv
          rowDiv.appendChild(errorDiv);
          containerDiv.appendChild(rowDiv);

          const noticesWrapper = document.querySelector(".woocommerce-notices-wrapper");

          // Append the containerDiv containing the rowDiv and errorDiv to the noticesWrapper
          noticesWrapper.appendChild(containerDiv);
        }

        return false;
      }

      // If the value is within the valid range, reset the errorShown flag
      errorShown = false;
      return true;
    }


    //click on plus
    for (var i = 0; i < plus.length; i++) {
      plus[i].addEventListener("click", function () {
        const inputElement = this.previousElementSibling;
        if (checkMaxValue(inputElement)) {
          setTimeout(function () {
            updateQty();
          }, 50);
        }
      });
    }

    //click on minus
    for (var i = 0; i < minus.length; i++) {
      minus[i].addEventListener("click", function () {
        updateQty();
        setTimeout(function () {
          updateQty();
        }, 50);
      });
    }
  }
}





document.addEventListener("DOMContentLoaded", function () {

  qtyPlusMin();

  jQuery(function ($) {
    $(document)
      .ajaxStart(function () { })
      .ajaxStop(function () {
        qtyPlusMin();

      });
  });
});
