document.addEventListener("DOMContentLoaded", function () {
    var count = 0;
    var topItem: HTMLCollection = document.getElementsByClassName(
      "site-header__nav--top-menu__wrap--usp__item"
    )!;
    var i: number = 0;
  
    for (i; i < topItem.length; i++) {}
  
    var topBarItemCounter = function () {
      if (i == topItem.length) {
        clearInterval(this);
        i = 0;
      } else {
        //   console.log("Currently at " + i++);
        var increment = i++;
        for (var item = 0; item < topItem.length; item++) {
          if (
            topItem[item].classList.contains("site-header__nav--top-menu__wrap--usp__item--active")
          ) {
            topItem[item].classList.remove("site-header__nav--top-menu__wrap--usp__item--active");
          } else {
          }
        }
        topItem[increment].classList.add("site-header__nav--top-menu__wrap--usp__item--active");
      }
    };
    setInterval(topBarItemCounter, 7000);
    topBarItemCounter();
  });