document.addEventListener("DOMContentLoaded", function () {
  const tabs: HTMLElement[] = Array.from(
    document.querySelectorAll(".hd-productspecs__wrap--tabs__tab")
  );
  const content: HTMLElement[] = Array.from(
    document.querySelectorAll(".hd-productspecs__content")
  );
  if (tabs.length === 0 && content.length === 0) return;
  for (let i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener("click", function (e) {
      tabs.forEach((tab) => {
        tab.classList.remove("tab-active");
      });
      content.forEach((content) => {
        content.classList.remove("content-active");
      });
      // Add 'active' class to the clicked tab
      this.classList.add("tab-active");
      content[i].classList.add("content-active");
    });
  }
});
