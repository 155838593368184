import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementsByClassName('splide-presentation')) {
    var sliders = document.querySelectorAll('.splide-presentation');
    sliders.forEach(function (slider) {
      new Splide(slider as HTMLElement, {
        type: 'slide',
        perPage: 1,
        perMove: 1,
        gap: 24,
        rewind: false,
        pagination: false,
        arrows: true,
        autoWidth: true,
        focus: 0,
        trimSpace: false,
        breakpoints: {
          768: {
            arrows: false,
            trimSpace: true,

          },
          425: {
            arrows: false,
          },
        },

      }).mount();
    });
  }

  if (document.getElementById('inspiration-slider')) {
    var slider = new Splide('#inspiration-slider', {
      type: 'loop',
      perPage: 1,
      perMove: 1,
      gap: 24,
      pagination: false,
      arrows: false,
      focus: 'center',
      start: 1,
      snap: true,
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
    }).mount({ AutoScroll });
  }
  if (document.getElementById('showcase-slider')) {
    var slider = new Splide('#showcase-slider', {
      type: 'slide',
      perPage: 1,
      perMove: 1,
      gap: 24,
      pagination: false,
      arrows: true,
      focus: 'center',
      fixedWidth: '356px',
      // start: 2,
      snap: true,
      breakpoints: {
        1024: {
          start: 0,
        },
      }
    }).mount();
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName("pdp-slider").length >= 1) {
    var thumbnails = new Splide(".sub-slider", {
      fixedWidth: 75,
      gap: 42,
      rewind: true,
      pagination: false,
      isNavigation: true,
      arrows: false,
      breakpoints: {
        768: {
          gap: 12,
        },
        1024: {
          gap: 24,
        },
      },
    });

    var splide = new Splide(".main-slider", {
      perPage: 1,
      pagination: false,
      type: "fade",
      rewind: true,
      duration: 400,
    });

    splide.sync(thumbnails);
    splide.mount();
    thumbnails.mount();

    var thummies: HTMLCollection =
      document.getElementsByClassName("sub-slider__slide")!;
    if (thummies.length >= 1) {
      var i: number = 0;
      for (i; i < thummies.length; i++) {
        thummies[i].addEventListener("click", function (e) {
          e.preventDefault();
        });
      }
    }
  }
});