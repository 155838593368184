// Function to handle clicks on the mini-cart-btn
function handleMiniCartButtonClick(event) {
  const cartContainer = document.querySelector(".site-nav__cart");

  // Check if the site-nav__cart__active class exists
  if (cartContainer.classList.contains("site-nav__cart__active")) {
    console.log("Success! site-nav__cart__active exists.");
  }

  // Prevent the click event from propagating to the document
  event.stopPropagation();
}

// Function to handle clicks outside the site-nav__cart
function handleDocumentClick(event) {
  const cartContainer = document.querySelector(".site-nav__cart");
  const miniCartButton = document.querySelector(".mini-cart-btn");

  // Check if the click target is outside the cart container
  if (!cartContainer.contains(event.target)) {
    // Remove the mini-cart-btn__active class
    miniCartButton.classList.remove("mini-cart-btn__active");
    cartContainer.classList.remove("site-nav__cart__active");
  }
}

// Add a click event listener to the mini-cart-btn
// Add a click event listener to the document to handle clicks outside the cart

const miniCartButton = document.querySelector(".mini-cart-btn");

if (miniCartButton) {
  miniCartButton.addEventListener("click", handleMiniCartButtonClick);
  document.addEventListener("click", handleDocumentClick);
}
