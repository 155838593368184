function searchFunc(btn, form, field) {
  var searchBtn = document.querySelector(btn);
  var searchForm = document.querySelector(form);
  var input = document.getElementById(field);
  var active = "search-is-active";

  document.addEventListener("keyup", function (event) {
    if (event.code === "Escape") {
      if (searchBtn.classList.contains("active-btn")) {
        searchBtn.classList.remove("active-btn");
        closer.classList.remove("search-closer__active");
        searchForm.classList.remove(active);
        input.value = "";
      }
    }

    if (event.code === "Enter") {
      params = new URLSearchParams({
        s: input.value,
      });
      if (
        searchBtn.classList.contains("active-btn") &&
        input.value.length >= 3
      ) {
        window.open("/?" + params.toString(), "_self");
      }
    }
  });

  document.onkeydown = function (e) {
    e = e || window.event;
    var isEnter = false;
    if ("key" in e) {
      isEnter = e.key === "Enter";
    } else {
      isEnter = e.code === 13;
    }

    if (
      isEnter &&
      input.value != "" &&
      input.length >= 3 &&
      searchBtn.classList.contains("active-btn")
    ) {
      params = new URLSearchParams({
        s: input.value,
      });
      console.log("howdy");
      window.open("/?" + params.toString(), "_self");
    }
  };
}

/* -------------------------------------------------------------------------- */
/*                               Desktop search                               */
/* -------------------------------------------------------------------------- */

document.addEventListener("DOMContentLoaded", function () {
    var searchCheck = document.getElementsByClassName("search-field")[0];

  if (searchCheck) {
    searchCheck.id="searchInput";
  }

    var searchResults = document.getElementById('search-results');
    var searchInput = document.getElementById('searchInput');

  jQuery(function ($) {
    $("#searchInput").on("input", function (e) {
      e.preventDefault();
        if ($(this).val().length >= 3) {
            searchResults.classList.add("search-form--results__active");
            searchInput.classList.add("search_results_active");

        $(this).addClass("search-active");
        $.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          dataType: "html",
          data: {
            action: "hd_search",
            query: $(this).val(),
          },
          beforeSend: function () {
            console.log("loading");
            $(".search-form--results").addClass(
              "search-form--results__loading"
            );
          },
          success: function (res) {
            $(".search-form--results").html(res);
            $(".search-form--results").removeClass(
              "search-form--results__loading"
            );
          },
        });
        } else if ($(this).val().length <= 0) {
            searchResults.classList.remove("search-form--results__active");
            searchInput.classList.remove("search_results_active");

        if ($(this).hasClass("search-active")) {
            $(this).removeClass("search-active");
            
        }

        $(".search-form--results").empty();
        if (
          $(".search-form--results").hasClass(
            "search-form--results__loading"
          )
        ) {
          $(".search-form--results").removeClass(
            "search-form--results__loading"
          );
        }
      }
    });
  });
});


/* -------------------------------------------------------------------------- */
/*                                Mobile search                               */
/* -------------------------------------------------------------------------- */


document.addEventListener("DOMContentLoaded", function () {

    var searchCheck = document.getElementsByClassName("search-field")[1];

  if (searchCheck) {
    searchCheck.id="searchInputMob";

  }

    var searchResults = document.getElementById('search-results-mobile');
    var searchInput = document.getElementById('searchInputMob');


  jQuery(function ($) {
    $("#searchInputMob").on("input", function (e) {
      e.preventDefault();
        if ($(this).val().length >= 3) {
            searchResults.classList.add("search-form--results__active");
            searchInput.classList.add("search_results_active");

        $(this).addClass("search-active");
        $.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          dataType: "html",
          data: {
            action: "hd_search",
            query: $(this).val(),
          },
          beforeSend: function () {
            console.log("loading");
            $(".search-form--results").addClass(
              "search-form--results__loading"
            );
          },
          success: function (res) {
            $(".search-form--results").html(res);
            $(".search-form--results").removeClass(
              "search-form--results__loading"
            );
          },
        });
        } else if ($(this).val().length <= 0) {
            searchResults.classList.remove("search-form--results__active");
            searchInput.classList.remove("search_results_active");

        if ($(this).hasClass("search-active")) {
            $(this).removeClass("search-active");
            
        }

        $(".search-form--results").empty();
        if (
          $(".search-form--results").hasClass(
            "search-form--results__loading"
          )
        ) {
          $(".search-form--results").removeClass(
            "search-form--results__loading"
          );
        }
      }
    });
  });
});