import Headroom from "headroom.js";

if(document.querySelector("#site-header")){
  let header = document.querySelector("#site-header");

  var options = {
    offset: 50,
    tolerance: 0,
    tolerance: {
      up: 5,
      down: 0,
    },
    classes: {
      initial: "site-header",
      pinned: "site-header--pinned",
      unpinned: "site-header--unpinned",
      top: "site-header--top",
      notTop: "site-header--not-top",
      bottom: "site-header--bottom",
      notBottom: "site-header--not-bottom",
      frozen: "site-header--frozen",
    },
    scroller: window,
  };
  
  let headroom = new Headroom(header, options);
  
  headroom.init();
}
