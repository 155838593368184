/* --------------------------------------------------------------------------*/
/*                              FAQ Rich Snippets                            */
/* --------------------------------------------------------------------------*/

document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementsByClassName("hd-content__faq--item").length >= 1) {
      var acc = document.getElementsByClassName("hd-content__faq--item");
      var panel = document.getElementsByClassName(
        "hd-content__faq--item__answer"
      );
  
      function setClass(els, className, fnName) {
        for (var i = 0; i < els.length; i++) {
          els[i].classList[fnName](className);
        }
      }
  
      for (var i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function (e) {
          var setClasses = !this.classList.contains(
            "hd-content__faq--item__active"
          );
          setClass(acc, "hd-content__faq--item__active", "remove");
          setClass(panel, "show", "remove");
  
          if (setClasses) {
            this.classList.toggle("hd-content__faq--item__active");
            this.nextElementSibling.classList.toggle("show");
          }
        });
      }
    }
  });
  