//custom code
import "./custom/mobile-menu";
import "./custom/scroller";
import "./custom/torus-animations";
import "./custom/search";
import "./custom/headroom";
import "./custom/faq";
import "./custom/splide";
import "./custom/woo";
import "./custom/productspecs";
import "./custom/ajax";
import "./custom/minicart_tweak";
import "./custom/mobile-scroll";

//libraries
import "./lib/fslightbox";
