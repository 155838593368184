import FsLightbox from 'fslightbox';

/* -------------------------------------------------------------------------- */
/*                             Mobile Menu Script                             */
/* -------------------------------------------------------------------------- */
document.addEventListener('DOMContentLoaded', () => {
    var hamBurger = document.getElementById('mobileToggle');
    var menu = document.getElementById('mobileMenu');
    hamBurger?.addEventListener('click', () => {
 if (menu?.classList.contains('site-header__nav--mobile__menu--open')) {
        menu?.classList.remove('site-header__nav--mobile__menu--open');
        hamBurger?.classList.remove('is-active');
    }

    else {
        menu?.classList.add('site-header__nav--mobile__menu--open');
        hamBurger?.classList.add('is-active');
    }
    })
   
});

document.addEventListener('DOMContentLoaded', function() {
        refreshFsLightbox();
    }

);

/* -------------------------------------------------------------------------- */
/*                                Submenu tweak                               */
/* -------------------------------------------------------------------------- */


document.addEventListener('DOMContentLoaded', function (event) {
  var parents = document.getElementsByClassName('main-menu__item--parent  main-menu__sub-menu__item');

  for (var i = 0; i < parents.length; i++) {
    var submenu = parents[i].querySelector('.main-menu__sub-menu');
    parents[i].addEventListener('mouseenter', function () {
      submenu.classList.add('active');
    });
    parents[i].addEventListener('mouseleave', function () {
      submenu.classList.remove('active');
    });
  }
});